<template>
  <div v-if="init" class="surveys">
    <div class="add-edit-survey" v-if="editingMode !== null">
      <div class="add-edit-survey-container">
        <span class="close material-icons" @click="clearEditing">close</span>
        <h2>add/edit survey</h2>
        <div class="search-select-wrapper">
          <div class="selected-label">Question</div>
          <input
            type="text"
            v-model="editingQuestion"
            placeholder="Enter question..."
            class="question-input"
          />
        </div>

        <div
          class="checkbox-select-wrapper"
          :class="{ disabled: editingMode !== 'create' }"
        >
          <div class="selected-label">Type</div>

          <div
            class="categories-check"
            @click="$store.commit('surveys/setEditingType', 'number')"
          >
            <div
              class="check"
              :class="{ active: editingType == 'number' }"
            ></div>
            <label>Number</label>
          </div>

          <div
            class="categories-check"
            @click="$store.commit('surveys/setEditingType', 'text')"
          >
            <div class="check" :class="{ active: editingType == 'text' }"></div>
            <label>Text</label>
          </div>
        </div>

        <div v-if="editingType == 'number'" class="search-select-wrapper">
          <div class="selected-label">Minimum label</div>
          <input
            type="text"
            v-model="editingMinLabel"
            placeholder="Enter label for lowest amount..."
            class="question-input"
            maxLength="22"
          />
          <span class="max-length">{{ editingMinLabel.length }} / 22</span>
        </div>

        <div v-if="editingType == 'number'" class="search-select-wrapper">
          <div class="selected-label">Max label</div>
          <input
            type="text"
            v-model="editingMaxLabel"
            placeholder="Enter label for highest amount..."
            class="question-input"
            maxLength="22"
          />
          <span class="max-length">{{ editingMaxLabel.length }} / 22</span>
        </div>

        <div class="search-select-wrapper">
          <div class="selected-label">Ending</div>
          <date-picker
            ref="editingEnding"
            v-model="editingEnding"
            type="datetime"
            valueType="format"
            :showSecond="false"
            placeholder="Select ending time"
            format="YYYY-MM-DD — HH:mm"
          ></date-picker>
        </div>

        <div class="footer-actions">
          <button
            v-if="editingMode !== 'create'"
            class="delete"
            @click="deleteSurvey"
          >
            Delete
          </button>
          <button class="save" @click="save">Save</button>
        </div>
      </div>
    </div>

    <div class="surveys-container">
      <div class="surveys-header">
        <h2>Surveys</h2>
        <button
          class="add-survey"
          @click="$store.commit('surveys/setEditingMode', 'create')"
          :class="{
            disabled: surveys.length > 0 && surveys[0].ending.toDate() > now,
          }"
        >
          Add survey
        </button>

        <div class="shortlists-filter">
          <div class="shortlists-dropdown">
            <div
              class="selected"
              @click="dropdownOpen = !dropdownOpen"
              :class="{ open: dropdownOpen }"
            >
              <span v-if="filter === 'all'">All</span>
              <span v-else>{{ getUserNameByVismaId(filter) }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="options" v-if="dropdownOpen">
              <div
                class="option"
                @click="selectFilter('all')"
                :class="{ active: 'all' === filter }"
              >
                All
              </div>
              <div
                class="option"
                v-for="option in getAdminUsers"
                :key="'admin-' + option.id"
                @click="selectFilter(option.vismaId)"
                :class="{ active: option.vismaId === filter }"
              >
                {{ option.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="surveys-list">
        <div class="survey" v-for="survey in surveys" :key="survey.id">
          <div class="survey-info">
            <div class="survey-question">{{ survey.question }}</div>
            <div>Type: {{ survey.type }}</div>
            <div v-if="survey.ending.toDate() > now" class="survey-ending">
              <span style="color: var(--color-success)">Active until:</span>
              {{ survey.ending.toDate() | moment("YYYY-MM-DD — HH:mm") }}
            </div>
            <div v-else>
              <span style="color: var(--color-danger)">Finished:</span>
              {{ survey.ending.toDate() | moment("YYYY-MM-DD — HH:mm") }}
            </div>
            <div class="survey-actions" v-if="survey.ending.toDate() > now">
              <div
                class="edit"
                @click="$store.dispatch('surveys/setupEditing', survey)"
              >
                <span class="material-icons edit-icon"> edit </span>
              </div>
            </div>
          </div>
          <div class="survey-answers">
            <div class="survey-answers-header">
              Answers -
              {{ Object.keys(filteredAnswers(survey.answers)).length }}
            </div>
            <div class="survey-number-stats" v-if="survey.type === 'number'">
              <div>
                <span>Average:</span>
                {{ surveyAverage(filteredAnswers(survey.answers)) }}
              </div>
              <div>
                <span>1:</span>
                {{ surveyAmountOf(filteredAnswers(survey.answers), 1) }}
              </div>
              <div>
                <span>2:</span>
                {{ surveyAmountOf(filteredAnswers(survey.answers), 2) }}
              </div>
              <div>
                <span>3:</span>
                {{ surveyAmountOf(filteredAnswers(survey.answers), 3) }}
              </div>
              <div>
                <span>4:</span>
                {{ surveyAmountOf(filteredAnswers(survey.answers), 4) }}
              </div>
              <div>
                <span>5:</span>
                {{ surveyAmountOf(filteredAnswers(survey.answers), 5) }}
              </div>
            </div>

            <div
              class="survey-answers-inner"
              v-if="showAnswers.includes(survey.id)"
            >
              <div
                v-for="(answer, userId) in filteredAnswers(survey.answers)"
                :key="survey.id + userId"
                class="survey-answer"
              >
                <div class="survey-answer-user">
                  <span class="name">{{ getUserNameById(userId) }}</span>
                  <span class="company">{{
                    getCompanyNameByVismaId(getUserCompanyById(userId))
                  }}</span>
                </div>
                <div
                  class="answer"
                  :class="{ number: survey.type === 'number' }"
                >
                  {{ answer }}
                </div>
              </div>
            </div>

            <button
              v-if="Object.keys(filteredAnswers(survey.answers)).length > 0"
              @click="toggleShowAnswers(survey.id)"
            >
              {{ showAnswers.includes(survey.id) ? "Dölj svar" : "Visa svar" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
export default {
  name: "Surveys",
  data() {
    return {
      loading: false,
      now: new Date(),
      dropdownOpen: false,
      showAnswers: [],
    };
  },
  components: { DatePicker },
  mounted() {},
  async created() {
    await new Promise((r) => setTimeout(r, 300));
    await this.$store.dispatch("surveys/bindSurveys");
    this.$store.commit("surveys/setInit", true);
    console.log(this.surveys);
  },
  beforeDestroy() {
    this.$store.dispatch("surveys/unbindSurveys");
    this.$store.commit("surveys/setInit", false);
  },
  computed: {
    editingQuestion: {
      get() {
        return this.$store.state.surveys.editingQuestion;
      },
      set(value) {
        this.$store.commit("surveys/setEditingQuestion", value);
      },
    },
    editingMinLabel: {
      get() {
        return this.$store.state.surveys.editingMinLabel;
      },
      set(value) {
        this.$store.commit("surveys/setEditingMinLabel", value);
      },
    },
    editingMaxLabel: {
      get() {
        return this.$store.state.surveys.editingMaxLabel;
      },
      set(value) {
        this.$store.commit("surveys/setEditingMaxLabel", value);
      },
    },
    editingEnding: {
      get() {
        return this.$store.state.surveys.editingEnding;
      },
      set(value) {
        this.$store.commit("surveys/setEditingEnding", value);
      },
    },
    ...mapState({
      init: (state) => state.surveys.init,
      userList: (state) => state.users.userList,
      appSettings: (state) => state.settings.appSettings,
      surveys: (state) => state.surveys.surveys,
      editingType: (state) => state.surveys.editingType,
      editingMode: (state) => state.surveys.editingMode,
      filter: (state) => state.surveys.filter,
    }),
    ...mapGetters("settings", ["notificationsOffPopups"]),
    ...mapGetters("analytics", [
      "analyticsDocById",
      "activeAnalyticsPushNotificationsDisabledCount",
      "activeAnalyticsSignedInPushNotificationsEnabledDocs",
      "activeAnalyticsSignedInDocs",
    ]),
    ...mapGetters("popups", [
      "pushDisabledPopups",
      "pushReminderPopups",
      "pushDisabledPopupsActive",
      "pushReminderPopupsActive",
      "pushNotificationsReminderUsers",
      "pushNotificationsDisabledCount",
    ]),
    ...mapGetters("users", [
      "getUserNameById",
      "getUserCompanyById",
      "getAdminUsers",
      "getUserNameByVismaId",
    ]),
    ...mapGetters("companies", [
      "getCompanyNameByVismaId",
      "getCompanyAccountOwnerByVismaId",
    ]),
  },
  methods: {
    clearEditing() {
      this.$store.dispatch("surveys/clearEditing");
    },
    async deleteSurvey() {
      let confirm = window.confirm(
        "Permanently delete price survey and its answers?\nThis can not be undone."
      );
      if (confirm) {
        // TODO : DELETE
        console.log("done");
        await this.$store.dispatch("surveys/deleteSurvey");
        this.$store.dispatch("surveys/clearEditing");
      }
    },
    async save() {
      console.log("here bby");
      await this.$store.dispatch("surveys/saveSurvey");
      this.$store.dispatch("surveys/clearEditing");
    },
    selectFilter: function (selected) {
      this.dropdownOpen = false;
      console.log(selected);
      this.$store.commit("surveys/setFilter", selected);
    },
    filteredAnswers: function (answers) {
      if (this.filter === "all") {
        return answers;
      }

      const returnObj = {};

      Object.keys(answers).forEach((uid) => {
        const companyId = this.getUserCompanyById(uid);
        const accountOwner = this.getCompanyAccountOwnerByVismaId(companyId);

        if (accountOwner === this.filter) {
          returnObj[uid] = answers[uid];
        }
      });

      return returnObj;
    },
    surveyAverage: function (answers) {
      let sum = 0;
      let count = 0;
      Object.keys(answers).forEach((uid) => {
        sum += answers[uid];
        count++;
      });

      if (count === 0) return parseFloat(0).toFixed(2);

      return parseFloat(sum / count).toFixed(2);
    },
    surveyAmountOf: function (answers, value) {
      let count = 0;
      Object.keys(answers).forEach((uid) => {
        if (answers[uid] === value) {
          count++;
        }
      });

      return count;
    },
    toggleShowAnswers: function (id) {
      if (this.showAnswers.includes(id)) {
        this.showAnswers = this.showAnswers.filter((item) => item !== id);
      } else {
        this.showAnswers.push(id);
      }
    },
  },
};
</script>
